import React, { useState, useEffect } from "react";
import { getDocument } from "../gatsbySafe";
import { GTAG_ID } from "../config";

const CookieConsentContext = React.createContext({});

const CONSENT_SAVED_KEY = "consent:saved";
const GA_KEY = "consent:GA";

const loadGoogleAnalytics = () => {
	const gtmScript = getDocument().createElement("script");
	gtmScript.setAttribute(
		"src",
		"https://www.googletagmanager.com/gtag/js?id=" + GTAG_ID
	);
	getDocument().body.appendChild(gtmScript);

	const analyticsScript = getDocument().createElement("script");
	analyticsScript.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${GTAG_ID}');
	`;
	getDocument().body.appendChild(analyticsScript);
};

export const CookieConsentProvider = ({ children }) => {
	const [gaEnabled, setGaEnabled] = useState(
		localStorage.getItem(GA_KEY) === "true"
	);
	const [consentSaved, setConsentSaved] = useState(
		localStorage.getItem(CONSENT_SAVED_KEY) === "true"
	);

	useEffect(() => {
		if (gaEnabled) {
			loadGoogleAnalytics();
		}
	}, [gaEnabled]);

	return (
		<CookieConsentContext.Provider
			value={{
				gaEnabled,
				setGaEnabled: enabled => {
					setGaEnabled(!!enabled);
					localStorage.setItem(GA_KEY, enabled ? "true" : "false");
					loadGoogleAnalytics();
				},
				consentSaved,
				saveConsent: () => {
					localStorage.setItem(CONSENT_SAVED_KEY, "true");
					setConsentSaved(true);
				},
			}}
		>
			{children}
		</CookieConsentContext.Provider>
	);
};

export default CookieConsentContext;
