/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it

import React from "react";
import { ThemeProvider } from "./src/contexts/ThemeContext";
import { CookieConsentProvider } from "./src/contexts/CookieConsentContext";

import "./src/styles/global.css";

const Providers = [ThemeProvider, CookieConsentProvider];

export const wrapRootElement = ({ element }) =>
	Providers.reduce(
		(children, NextProvider) => <NextProvider>{children}</NextProvider>,
		element
	);
