// Seems to avoid the "window is not defined" and "localStorage is not defined"
// SSR issues
export const getLocalStorage = () =>
	typeof window !== "undefined"
		? window.localStorage
		: {
				getItem: () => null,
				setItem: () => {},
		  };

// Flesh out these placeholders as needed
export const getDocument = () =>
	typeof window !== "undefined"
		? window.document
		: {
				createElement: () => undefined,
				querySelector: () => undefined,
				querySelectorAll: () => [],
				body: {
					appendChild: () => {},
				},
		  };

export const clientSideOnly = fn => {
	if (typeof window !== "undefined") {
		fn();
	}
};

export const getNavigator = () =>
	typeof window != "undefined"
		? window.navigator
		: {
				userAgent: "",
		  };
