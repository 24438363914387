import React, { useState } from "react";

const ThemeContext = React.createContext({});

const LOCAL_STORAGE_KEY = "preferDarkMode";

const getDefaultState = () => {
	if (localStorage.getItem(LOCAL_STORAGE_KEY) === "true") {
		return true;
	}

	if (localStorage.getItem(LOCAL_STORAGE_KEY) === "false") {
		return false;
	}

	return window.matchMedia("(prefers-color-scheme: dark)").matches === true;
};

export const ThemeProvider = ({ children }) => {
	const [dark, setDark] = useState(getDefaultState());

	return (
		<ThemeContext.Provider
			value={{
				dark,
				toggleDark: () => {
					setDark(!dark);
					localStorage.setItem(LOCAL_STORAGE_KEY, !dark);
				},
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

export default ThemeContext;
